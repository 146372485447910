<template>
  <div>
    <v-card>
      <h2 class="pl-2">
        Cele
      </h2>
      <v-btn
        color="blue"
        class="float-right mr-10 white--text"
        @click.stop="addOrEdit()"
      >
        Dodaj grupę celów
        <v-icon class="ml-2 white--text">
          mdi-plus
        </v-icon>
      </v-btn>
      <div style="clear:both"></div>
      <v-data-table
        :headers=headers
        :items=targets
        :options.sync="options"
        :server-items-length="serverItemsLength"
      >
        <template slot="item.type" slot-scope="props">{{ props.item.type === 2 ? "Konkurs" : "Quiz" }}</template>
        <template
          slot="item.isAttached"
          slot-scope="props"
          class="justify-center"
        >
          <v-checkbox
            style="pointer-events: none"
            readonly
            v-model="props.item.isAttached"
            label=""
            color="primary"
          ></v-checkbox>
        </template>
        <template
          slot="item.actions"
          slot-scope="props"
          class="justify-center"
        >
          <v-btn
            class="mx-2"
            icon
            @click="addOrEdit(props.item.id !== 0 ? JSON.parse(JSON.stringify(props.item)): null, props.item.type)"
          ><v-icon dark>mdi-pencil</v-icon>
          </v-btn>
          <v-btn
            class="mx-2"
            icon
            @click="deleteItem(props.item)"
          ><v-icon dark>mdi-delete</v-icon>
          </v-btn>
        </template>

      </v-data-table>
    </v-card>

    <confirm-delete-dialog
      endPoint="/target/DeleteNewTargetsGroupConfiguration"
      ref="confirmDeleteDialog"
      @submit="getDataAsync"
    ></confirm-delete-dialog>
  </div>
</template>
<script>
import ConfirmDeleteDialog from '@/components/Dialogs/ConfirmDeleteDialog'

export default {
  name: 'Targets',
  components: {
    ConfirmDeleteDialog
  },
  data () {
    return {
      headers: [
        { text: this.$t('target.name'), value: 'name' },
        { text: this.$t('target.attached'), value: 'isAttached' },
        { text: this.$t('target.from'), value: 'from' },
        { text: this.$t('target.to'), value: 'to' },
        { text: this.$t('product.actions'), value: 'actions', width: '10%', sortable: false }
      ],
      targets: [],
      options: {},
      serverItemsLength: 0,
      endpoint: '/target/GetNewTargetsGroupConfigurations'
    }
  },
  methods: {
    async getDataAsync () {
      let url = this.endpoint

      if (this.options.page) {
        url += '?'
        url += 'options.page=' + this.options.page + '&' +
          'options.itemsPerPage=' + this.options.itemsPerPage + '&' +
          '&options.sortBy=' + (this.options.sortBy !== null && this.options.sortBy.length > 0 ? this.options.sortBy[0] : 'from') +
          '&options.sortDesc=' + (this.options.sortBy !== null && this.options.sortDesc.length > 0 ? this.options.sortDesc[0] : true)
      }

      const result = await this.$http.get(url)

      this.targets = result.data.results
      this.serverItemsLength = result.data.totalCount
    },
    deleteItem (item) {
      this.$refs.confirmDeleteDialog.dialog = true
      this.$refs.confirmDeleteDialog.message = item.name
      this.$refs.confirmDeleteDialog.id = item.id
    },
    async addOrEdit (product) {
      const routerName = 'AddOrEditTargets'
      if (product) {
        await this.$router.push({ name: routerName, params: { id: product.id } })
      } else {
        await this.$router.push({ name: routerName })
      }
    }
  },
  watch: {
    options: {
      handler () {
        this.getDataAsync()
      },
      deep: true
    }
  }
}
</script>
